import React, { useState, useRef, useEffect } from 'react';
import API from 'axios';
import './App.css';

import logo from "./images/logo.png";

const App = () => {
  const [navigation, setNavgiation] = useState('points');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const pointsRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > contactRef.current.offsetTop - 151) setNavgiation('contact');
      else if (offset > aboutRef.current.offsetTop - 151) setNavgiation('about');
      else if (offset > pointsRef.current.offsetTop - 151) setNavgiation('points');
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className="header">
        <div className="top-bar">
          <a href="mailto:info@informing-m.hr" className="top-bar-link"><span className="material-icons">mail</span><span>info@informing-m.hr</span></a>
          <a href="tel:+385 1 2002 166" className="top-bar-link"><span className="material-icons">call</span><span>+385 1 2002 166</span></a>
        </div>
        <div className="navigation">
          <div className="logo">
            <img src={logo} alt="Informing-M logo" />
          </div>
          <ul className="menu">
            <li className="menu-item">
              <a href='#points' className={`menu-link${navigation === 'points' ? ' active' : ''}`} onClick={() => window.scrollTo({
                left: 0,
                top: pointsRef.current.offsetTop - 150,
                behavior: 'smooth',
              })}>Naslovna</a>
            </li>
            <li className="menu-item">
              <a href='#about' className={`menu-link${navigation === 'about' ? ' active' : ''}`} onClick={() => window.scrollTo({
                left: 0,
                top: aboutRef.current.offsetTop - 150,
                behavior: 'smooth',
              })}>Usluge</a>
            </li>
            <li className="menu-item">
              <a href='#contact' className={`menu-link${navigation === 'contact' ? ' active' : ''}`} onClick={() => window.scrollTo({
                left: 0,
                top: contactRef.current.offsetTop - 150,
                behavior: 'smooth',
              })}>Kontakt</a>
            </li>
            <a href="https://etrgovina.hr" className="store">Naša web trgovina<span className="material-icons">arrow_forward</span></a>
          </ul>
        </div>
      </div>
      <div ref={pointsRef} className="container top-offset">
        <div className="card">
          <div className="card-icon">
            <span className="material-icons">insert_chart_outlined</span>
          </div>
          <h2>Rješenja za praćenje<br />poslovanja</h2>
        </div>
        <div className="card">
          <div className="card-icon">
            <span className="material-icons">devices</span>
          </div>
          <h2>Informatička oprema<br />i sistemski softver</h2>
        </div>
        <div className="card">
          <div className="card-icon">
            <span className="material-icons">settings_input_component</span>
          </div>
          <h2>Implementacija<br />informatičke opreme</h2>
        </div>
        <div className="card">
          <div className="card-icon">
            <span className="material-icons">chat_bubble_outline</span>
          </div>
          <h2>Savjetodavne usluge</h2>
        </div>
      </div>
      <div ref={aboutRef} className="container flat">
        <div>
          <h1>Naše usluge</h1>
          <div className="card flat">
            <h2>Programsko rješenje za praćenje poslovanja</h2>
            <h3>ERP poslovni sustav</h3>
            <h4>Upravljanje poslovnim procesima</h4>
            <p>Sustav koji povezuje sve poslovne aktivnosti poduzeća u kontroliranu cjelinu. Rješenje za upravljanje
              kompleksnim i velikim poslovanjima.</p>
            <p>Namijenjen je malim, srednjim i velikim tvrtkama i ustanovama iz privatnog i javnog sektora koje trebaju
              snažan i pouzdan poslovni sustav kako bi objedinili praćenje i kontrolu svih poslovnih procesa i povećale
              svoju konkurentnost.</p>
            <h4>ERP poslovni sustav donosi</h4>
            <ul>
              <li>ubrzanje i standardizaciju operativnih procesa</li>
              <li>bolju kontrolu i lakše upravljanje na svim nivoima</li>
              <li>smanjenje troškova kroz pouzdane i slijedive podatke i informacije</li>
              <li>uvid u sve poslovne događaje i praćenje svih poslovnih aktivnosti</li>
              <li>neograničen izvor podataka za analizu</li>
              <li>fleksibilnost prilikom poslovnih promjena</li>
            </ul>
            <h4>Primjenjivost poslovnog rješenja</h4>
            <ul>
              <li>veleprodaja i distribucija, komisijska prodaja</li>
              <li>direktna prodaja, maloprodaja, MLM</li>
              <li>usluge u građevinarstvu, projektne i intelektualne usluge</li>
              <li>informatika, posredovanje, servisne djelatnosti</li>
              <li>jednostavna proizvodnja, pojedinačna i maloserijska proizvodnja</li>
              <li>više procesna i kompleksna serijska proizvodnja</li>
            </ul>
            <h2>Nabava informatičke opreme i sistemskog softvera</h2>
            <h4>Prodaja</h4>
            <ul>
              <li>prodaja hardvera i mrežne opreme</li>
              <li>prodaja softvera</li>
              <li>prodaja potrošnog materijala</li>
            </ul>
            <h2>Instalacija i implementacija informatičke opreme</h2>
            <h4>Sistemska podrška</h4>
            <ul>
              <li>nstalaciju (postavljanje) mrežne infrastrukture</li>
              <li>instalaciju i konfiguraciju poslužitelja (servera)</li>
              <li>instalacija i konfiguraciju radnih stanica</li>
              <li>održavanje poslužitelja i radnih stanica</li>
              <li>davanje preporuka za moguća unaprjeđenja informatičkog sustava</li>
              <li>davanje preporuka za postizanje veće stabilnosti i sigurnosti informatičkog sustava</li>
              <li>izradu i održavanje tehničke dokumentacije informatičkog sustava</li>
            </ul>
            <h2>Savjetodavne usluge</h2>
            <h4>Konzalting</h4>
            <ul>
              <li>analize i unaprjeđenja postojećih sustava i infrastrukture</li>
              <li>pomoć pri nabavi i izboru nove opreme</li>
              <li>pomoć pri izboru hardverskih i softverskih platformi</li>
              <li>rješavanje problema vezanih uz konfiguraciju i optimizaciju</li>
            </ul>
          </div>
        </div>
        <div ref={contactRef}>
          <h1>Kontakt</h1>
          <div className="card flat">
            {success && <div className="alert"><b style={{ fontSize: '1.25rem', paddingBottom: '1rem', display: 'block' }}>Vaš upit je uspješno zaprimljen.</b><br />Javit ćemo Vam se u najkraćem mogućem roku.</div>}
            {!success && (
              <form onSubmit={(event) => {
                event.preventDefault();
                setLoading(true);
                const params = new FormData();
                params.append('name', name.trim());
                params.append('email', email.trim().toLowerCase());
                params.append('phone', phone.trim());
                params.append('message', message.trim());
                API.post('https://informing-m.hr/api/contact/', params)
                  .then((response) => {
                    console.log(response.data)
                    if (response.data === 1) setSuccess(true);
                    setLoading(false);
                  }).catch((error) => {
                    console.log(error, error.response)
                    setLoading(false)
                  })
              }}>
                <fieldset disabled={loading}>
                  <input value={name} type="text" className="field" minLength={5} maxLength={50} required placeholder="Ime" onChange={(event) => setName(event.target.value)} />
                  <input value={email} type="email" className="field" minLength={5} maxLength={100} required  placeholder="Email" onChange={(event) => setEmail(event.target.value)} />
                  <input value={phone} type="tel" className="field" required placeholder="Telefon" onChange={(event) => setPhone(event.target.value)} />
                  <textarea value={message} className="field" rows="3" minLength={50} maxLength={500} required placeholder="Poruka" onChange={(event) => setMessage(event.target.value)} />
                  <button type="submit" className="button">{loading ? 'Šaljem...' : 'Pošalji'}</button>
                </fieldset>
              </form>
            )}
          </div>
          <h1 style={{ marginTop: '2rem' }}>Podaci</h1>
          <div className="card flat">
            <div className="contact"><span>Tvrtka</span><span>InformING-M d.o.o.</span></div>
            <div className="contact"><span>OIB</span><span>23626358811</span></div>
            <div className="contact"><span>Adresa</span><span>Šimunčevečka 8, Sesvete</span></div>
            <div className="contact"><span>Telefon</span><span>+385 1 2002 166</span></div>
            <div className="contact"><span>Email</span><span>info@informing-m.hr</span></div>
            <div className="contact"><span>Kontakt osoba</span><span>Željko Moštak</span></div>
            <div className="contact"><span>Telefon</span><span>+385 98 468 118</span></div>
            <div className="contact"><span>Email</span><span>zeljko.mostak@informing-m.hr</span></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
